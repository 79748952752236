.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hero-main-img {
  height: 100%;
}

.book_collaps {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  cursor: pointer;
}

.book_collaps:hover {
  background-color: #d0cccc;
}

.book_collaps p {
  text-align: center;
}

.book_collaps p:nth-child(1) {
  margin: 0;
  /* padding: 10px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #61dafb;
  text-align: center;
  z-index: 1;
}


.book_btn {
  padding: 10px 40px;
  max-width: 250px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
}

.book_btn :hover {
  color: #1591e1;
}

.overlaybg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
}

.new_overlaybg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bannerCaption {
  margin: 0px 0px;
}

.bannerCaption p {
  background: transparent !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  margin: 0 !important;
  text-align: justify !important;
}

.hyperLink {
  color: rgb(173, 230, 182);
  text-decoration: none;
  cursor: pointer;
}

.hyperLink :hover {
  color: #2874f0;
}

.fontChange {
  /* font-family: Garamond, serif;
  font-weight: 800; */
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 1px;
}

.touroverview {
  min-width: 400px;
  min-height: 400px;
  border-radius: 8px;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 5px 1px rgb(192, 192, 192);
}

.touroverview p {
  font-weight: 600;
  padding: 0;
  margin: 0;
}



.touroverdesc {
  min-width: 400px;
  flex: 1;
  min-height: 400px;
  border-radius: 8px;
  /* padding: 20px 30px; */
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgb(192, 192, 192);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.touroverdesc p {
  font-weight: 600;
  padding: 0;
  margin: 0;
}


.tourdetails_grid {
  display: flex;
  gap: 20px;
}

.tourdetails_table {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  background-color: #f0f7ff;

}

.tourdetails_tablet {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  /* background-color: white; */

}

.tourdetails_title {
  padding: 15px 10px;
  background: #06aaf1;
  color: #fff;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.desctour {
  padding: 20px 40px;
  text-align: center;
}

.hello p:nth-last-child(1) {
  display: none;
}

/* Add your existing styles here */
/* Your existing styles here */

.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.dot {
  width: 26px;
  /* Double the size of the circles */
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: transparent;
  border: 2px solid #fff;
  width: 26px;
  /* Double the size of the active circle */
  height: 26px;
  position: relative;
}

.dot.active::before {
  content: '';
  position: absolute;
  top: 24%;
  left: 24%;
  transform: translate(-50%, -50%);
  width: 15px;
  /* Double the size of the inner circle */
  height: 15px;
  margin: 6px;
  /* Double the margin to create space between the circles */
  border-radius: 50%;
  background-color: #fff;
}

.login-bg .login_container {
  margin: 0 auto !important;
  padding: 0px 15px !important;
  box-shadow: unset !important;
  border-radius: 0px !important;
}

.login_container {
  margin: 17px auto;
  padding: 20px;
  box-shadow: 0 0 20px #ccc;
  border-radius: 10px;
}

.login_container label {
  /* color: #224289;
  font-weight: bold; */
  font-family: "Poppins";
}

.login_container h4 {
  color: #224289;
  font-weight: bold;
  /* text-align: center; */
}

.login_container span {
  color: #224289;
  font-weight: bold;
  cursor: pointer;
}

.login_container .form-control {
  border: none !important;
  background-color: #f0f0f0 !important;
  padding-left: 40px !important;
}
#note
{
  padding-left: 5px !important;
}
#google_input_icon{
  padding-left: 5px !important;
}

#input-from{
padding-left: 55px!important;
}
#input-to
{
  padding-left: 39px!important;
}

.custom-carousel-control {
  font-size: 24px; /* Adjust size as needed */
  color: black; /* Set the color to black */
  cursor: pointer; /* Add pointer cursor on hover */
}

.login_container .designi {
  transform: scale(1.5);
  position: absolute;
  top: 12px;
  left: 12px;
  opacity: 1;
  color: #989898;
}
.home_icon{
  transform: scale(1.5);

  top: 12px;
  left: 12px;
  opacity: 1;
  color: #989898;
}

.login_container .text-right {
  opacity: 0.7;
  cursor: pointer;
}

/* .login_container button {

  font-weight: bold;
  background-color: #00627e !important;
  color: #ffffff !important;
  outline: none;
} */



button:focus {
  outline: none;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.checkbox-label input {
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #3498db;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.checkbox-label input:checked {
  background-color: #3498db;
  border: 2px solid #3498db;
}

.checkbox-label input:focus {
  box-shadow: 0 0 5px rgba(52, 152, 219, 1);
}

/* Custom styles for the payment inputs */
.custom-payment-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-card-image {
  width: 50px;
  /* Adjust the width as needed */
  margin-bottom: 10px;
}

.custom-card-number-input,
.custom-expiry-date-input,
.custom-cvc-input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.custom-card-number-input::placeholder,
.custom-expiry-date-input::placeholder,
.custom-cvc-input::placeholder {
  color: #999;
}

/* Optional: Style for the focused input */
.custom-card-number-input:focus,
.custom-expiry-date-input:focus,
.custom-cvc-input:focus {
  border-color: #007bff;
  /* Change to your preferred focus color */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Change to your preferred focus shadow */
}


/* .print-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 17px 12px !important;

  margin-bottom: 5px;
  
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #4caf50;

}

.print-btn:hover {
  background-color: transparent;
  border: 1px solid #4caf50;
  color: #4caf50;
  padding: 17px 12px !important;
  margin-bottom: 5px;
} */





.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}


.custom-carousel-container {
  position: relative;
  width: 100%;
}

.custom-carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.custom-carousel-control {
  cursor: pointer;
  font-size: 2rem;
  color: #000;
  pointer-events: all;
  user-select: none;
}

.prev-control {
  position: absolute;
  left: 10px;
  transform: translateY(-50%);
}

.next-control {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
}

.signature-container {
  position: relative;
  width: 300px;
  height: 70px;
 
}

/* .signatureCanvas {
  border: 1px solid #000;
}

.signature-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #666;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  pointer-events: none; 
}

@media print {
  .signature-placeholder {
    display: none;
  }
  .signature-container{
    border: none;
  }
 
} */


.page-head .breadcrums {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.page-head .breadcrums .page-title {
  margin-bottom: 0;
  margin-right: 30px;
}

.page-head .breadcrums .b-link {
  margin: 0 0.5rem;
  color: #b1b1ca;
  font-size: 14px;
}
.page-head .breadcrums .b-link.active {

  color: #6d7290;
}

.page-head .breadcrums .b-link:hover {
  text-decoration: underline;
}


.breadcrums-bar {
  padding: 15px 30px 5px 25px !important;
}

.carousel .control-dots .dot.selected{
  display: none!important;
}

/* claim form */

.tabs {
  display: flex;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.tab {
  padding: 10px 20px;
  background-color: lightgray;
  color: black;
  border-radius: 30px;
  margin-right: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}



.active-tab {
  background-color: #0d6efd;
  color: white;
}

.form-check-input {
  width: 1.5em!important;
  height: 1.5em!important;
}
/* Header.css */
.header {
  /* background-color: #007bff; */
  color:  #007bff;
  padding: 20px 0;
  text-align: center;
}

.header h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  letter-spacing: 1px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); /* Adds subtle shadow */
}

.extra-light {
  background-color: #f9f9f9!important; 
  color: #84888b!important;
  border-color: #dee2e6!important; 
}

.navbar-toggler.navbar-dark .navbar-toggler 
{
  width: "20%!important";
}