/* General Styles */
.nav {
    position: relative;
  }

  .navbar .nav-dropdown-title {
    color: black !important; 
}


.navbar .nav-dropdown-title:hover {
    color: blue !important; 
}


/* .navbar{
  background-color: #00627e!important;
} */

  .nested-dropdown .dropdown-menu {
    background-color: black !important; /* Set the background color for nested dropdowns */
    color: white; /* Set text color for visibility */
  }
  
  .nested-dropdown .dropdown-item {
    color: white; /* Ensure dropdown item text color is white */
  }
  
  .nested-dropdown .dropdown-item:hover {
    background-color: #333; /* Change hover color for better visibility */
  }
  
  .navbar {
    background-color: #343a40; 
  }
  
  .navbar .nav-link {
    color: white !important; /* White text */
  }
  


  .nav__checkbox {
    display: none;
  }
  
  .nav__toggle {
    cursor: pointer;
    display: none;
  }
  
  .nav__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
   
  }
  
  .nav__item {
    position: relative;
  }
  
  .nav__link {
    color: white;
    font-weight: bolder;
    padding: 10px 15px;
    text-decoration: none;
    display: block;
  }
  
  .nav__dropdown:hover .nav__submenu {
    display: block;
  }

  .nav__submenu-item li,.nav__submenu-item Link {
    text-align: left;
  }

  
  .nav__submenu {
    position: absolute;
    left: 10px;
    width: 200px;
    display: none;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    background: white;
    font-size: 15px;
    border-radius: 10px;
    padding-bottom: 25px;
    padding-top: 15px;
    text-align: left;
    
  }
  
  .nav__submenu-right {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: auto;
    border-radius: 10px;
    text-align: left;
   
  }
  
  .nav__submenu-item, .nav__submenu-right-item {
    position: relative;
    text-align: left;
  }
  
  .nav__submenu-link, .nav__submenu-right-link {
    color: #00627e;
    font-weight: bolder;
    padding: 10px 15px;
    text-decoration: none;
    display: block;
    white-space: nowrap;
  }
  
  .nav__submenu-item:hover .nav__submenu-right {
    display: block;
  }
  
  .nav__logo {
    display: block;
    margin: -56px auto;
  }
  
  .fa-chevron-down, .fa-chevron-right {
    padding: 3px;
  }
  
 
.nav__submenu-item,.nav__submenu-right-item,.nav__submenu-right-link{
  min-width: 100%!important;
  text-align: left;

}


/* displaying both nav  */

.website_navbar,
.drawer_navbar {
  display: none; /* Hide both navbars by default */
}


@media (min-width: 1200px) {
  .website_navbar {
    display: block; /* Show website navbar */
  }
}


@media (max-width: 1200px) {
  .drawer_navbar {
    display: block; /* Show drawer navbar */
  }
}